import { useEffect, useState } from "react"
import { useInterval } from './useInterval'
import Cookies from 'js-cookie'

export function useF5DeviceID() {
    const [fingerprint, setFingerprint] = useState(null)
    const [loading, setLoading] = useState(true)
    const [pollInterval, setPollInterval] = useState(1000)

    useEffect(() => {
        let script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://dip.zeronaught.com/__imp_apg__/js/f5cs-a_aakg_0klbe-8eca3f60.js'
        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    useInterval(() => {
        const cookie = Cookies.get('_imp_apg_r_')
        console.log(cookie)

        if(!cookie) return

        setLoading(false)
        setFingerprint(JSON.parse(cookie))
        setPollInterval(null)
    }, pollInterval)
    
    return [fingerprint, loading]
}