import React, { useEffect, useState } from "react";

import { CookiesProvider, useCookies } from "react-cookie";
import { v4 as uuidv4 } from 'uuid';
import { useF5DeviceID } from "../useF5DeviceId";
import { Container, Panel } from "components";

const Home: React.FC = () => {

    const [ip, setIp] = useState(null)
    const [cookies, setCookie] = useCookies(['cookieId'])
    const [did] = useF5DeviceID()

    useEffect(() => {
        console.log(cookies)
        if(!cookies.cookieId)
            setCookie('cookieId', uuidv4(), {path: '/'})

        fetch("https://api.ipify.org?format=json", {
            method: 'get'
        })
        .then(res => res.json())
        .then(data => { setIp(data.ip) })
    }, [cookies])

    return (
        <CookiesProvider>
        <Container>
            <div style={{minWidth: '100vw', minHeight: '100vh'}} className="flex flex-row justify-center items-center">
            <div className="grid grid-cols-1 md:grid-cols-3 auto-cols-max gap-x-20">
                <Panel fingerprint={ip} name="IP Address" set={1} getId={ip => ip} />
                <Panel fingerprint={cookies} name="Basic Cookie Identifier" set={4} getId={cookies => cookies.cookieId} />
                <Panel fingerprint={did} name="F5 DeviceID+ (diB)" set={5} getId={did => did.diB} getText={did =>`diA: ${did.diA}<br />diB: ${did.diB}`} />
            </div>
            </div>
        </Container>
        </CookiesProvider>
    );
};

export default Home;
