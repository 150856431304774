import React from 'react';

interface PanelProps<F> {
    fingerprint: F | null
    getId: (fingerprint: F) => string
    name: string
    set: number
    getText?: (fingerprint: F) => string
}

export async function getServerSideProps(context) {
return {
    props: {}, // will be passed to the page component as props
}
}

export const Panel = <F extends object>(props: PanelProps<F>) => {
    const getText = props.getText || props.getId
    return (
        <div className="flex flex-col justify-start items-center">
            {(props.fingerprint && props.getId(props.fingerprint))
            ? <img src={`https://robohash.org/${props.getId(props.fingerprint)}.png?set=set${props.set}`} alt={`${props.name}: ${props.getId(props.fingerprint)}`} height={200} width={200} />
            : <div style={{width: '200px', height: '200px'}} className="flex flex-col justify-center items-center text-center">Loading...</div>
            }

            <div className="font-bold text-center mt-6">{props.name}</div>

            { (props.fingerprint && getText(props.fingerprint)) &&
            <div className="text-center font-mono text-gray-500 mb-10" dangerouslySetInnerHTML={{__html: getText(props.fingerprint)}}></div>
            }
        </div>
    )
}

export default Panel