import React, {useEffect, useRef } from 'react';

export const useInterval = (callback, delay) => {

  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback])


  useEffect(() => {
    const tick = () => {
        const callback = savedCallback.current || null
        if(callback)
            callback()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}